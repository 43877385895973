import { ComponentRef, FC, lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DashboardBlock from '../../components/client-dashboard/DashboardBlock';
import QuickLinkWidget from '../../components/home-page/QuickLinkWidget';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import SkeletonLoader from '../../components/shared/skeleton-loader/SkeletonLoader';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import Tooltip from '../../components/shared/Tooltip';
import { useNotifications } from '../../hooks/useNotifications';
import { ClientFormUserStats } from '../../models/ClientFormUserStats';
import ClientFormService from '../../services/ClientFormService';
import ClientService from '../../services/ClientService';
import DateUtils from '../../utils/DateUtils';
import { FavouriteForm } from '../../models/Form';
import PlusIcon from '../../components/shared/icon/PlusIcon';
import CogIcon from '../../components/shared/icon/CogIcon';
import { Roles } from '../../models/Role';
import TabButton from '../../components/shared/tab-strip/TabButton';
import { ActivitiesContext } from '../../contexts/ActivitiesContext';
import usePermissions from '../../hooks/permissions/usePermissions';
import { EventSystem } from '../../events/EventSystem';
import Activities from '../../components/activity-list/Activities';
import { FormType } from '../../models/FormTypes';
import FunnelIcon from '../../components/shared/icon/FunnelIcon';
import { SelectListMenu } from '../../components/shared/SelectListMenu';
import Checkbox from '../../components/shared/form-control/Checkbox';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { useCurrentUser } from '../../global-state/Auth';
import { useCurrentClient } from '../../global-state/Clients';

const ActivitiesChart = lazy(() => import('../../components/home-page/ActivitiesChart'));
const HomePageNotifications = lazy(() => import('../../components/notifications/HomePageNotifications'));

export const UserStatsActivitiesOption = {
  id: 'activities',
  text: 'blocks.left.stats.filters.activities',
  value: [FormType.Asset, FormType.Default, FormType.Startup, FormType.SubForm, FormType.SubFormWithApproval],
} as const;

export const UserStatsDocumentsOption = {
  id: 'documents',
  text: 'blocks.left.stats.filters.documents',
  value: [FormType.Document],
} as const;

export const UserStatsFilterAllDefault = [UserStatsActivitiesOption, UserStatsDocumentsOption];
const UserstatsFilterAllCount = UserStatsFilterAllDefault.length;

const HomePage = () => {
  const { t } = useTranslation(['home-page', 'common']);
  const currentUser = useCurrentUser((x) => x.value);
  const currentClient = useCurrentClient((x) => x.value);

  const [userStats, setUserStats] = useState<ClientFormUserStats>();
  const [isStatsLoading, setIsStatsLoading] = useState(true);
  const [userStatsFilterOpen, setUserStatsFilterOpen] = useState(false);

  const [quickLinks, setQuickLinks] = useState<FavouriteForm[]>([]);
  const [isQuickLinksLoading, setIsQuickLinksLoading] = useState(true);
  const quickLinksRef = useRef<ComponentRef<typeof QuickLinkWidget>>(null);
  const [isMyWorkSelected, setIsMyWorkSelected] = useState(true);
  const hasPermission = usePermissions();

  const userStatsFilterOptions = useMemo<(Omit<(typeof UserStatsFilterAllDefault)[number], 'text'> & { text: string })[]>(
    () => UserStatsFilterAllDefault.map((x) => ({ ...x, text: t(x.text) })),
    [t],
  );
  const [userStatsFilter, setUserStatsFilter] = useState(userStatsFilterOptions);

  const greeting = useMemo(
    () => t('blocks.left.header', { firstname: currentUser?.firstName, partOfDay: DateUtils.getPartOfDay(t) }),
    [currentUser?.firstName, t],
  );

  const { unread: unreadNotifications } = useNotifications({ useCurrentClient: true });
  const [notificationsLoader, setNotificationsLoader] = useState(false);

  useEffect(() => {
    const handler = () => setNotificationsLoader(true);
    EventSystem.listen('language-changed', handler);
    return () => {
      EventSystem.stopListening('language-changed', handler);
    };
  }, []);

  useEffect(() => {
    if (!currentClient?.id) {
      return;
    }

    setIsStatsLoading(true);
    ClientFormService.getUserFormStats(userStatsFilter.flatMap((x) => x.value))
      .then((res) => setUserStats(res.data))
      .finally(() => setIsStatsLoading(false));
  }, [currentClient?.id, userStatsFilter]);

  const toggleUserStatItem = useCallback((item: (typeof userStatsFilterOptions)[number]) => {
    setUserStatsFilter((prev) => {
      const enabledItems = prev.map((x) => x.id);

      // Clicked on the documents option
      if (item.id === UserStatsDocumentsOption.id) {
        if (enabledItems.includes(UserStatsDocumentsOption.id)) {
          return prev.filter((x) => x.id !== UserStatsDocumentsOption.id);
        } else {
          return [...prev, UserStatsDocumentsOption];
        }
      }

      // Clicked on the activities option
      if (item.id === UserStatsActivitiesOption.id) {
        if (enabledItems.includes(UserStatsActivitiesOption.id)) {
          return prev.filter((x) => x.id !== UserStatsActivitiesOption.id);
        } else {
          return [...prev, UserStatsActivitiesOption];
        }
      }

      return prev;
    });
  }, []);

  const fetchQuickLinks = useCallback(() => {
    if (!currentClient) {
      return;
    }

    setIsQuickLinksLoading(true);
    ClientService.getClientQuickLinks().then((res) => {
      setQuickLinks(res.data);
      setIsQuickLinksLoading(false);
    });
  }, [currentClient]);

  useEffect(() => {
    fetchQuickLinks();
  }, [fetchQuickLinks]);

  const onMyWorkClick = useCallback(() => {
    if (!isMyWorkSelected) {
      setIsMyWorkSelected(true);
    }
  }, [isMyWorkSelected]);

  const onMyTeamClick = useCallback(() => {
    if (isMyWorkSelected) {
      setIsMyWorkSelected(false);
    }
  }, [isMyWorkSelected]);

  return (
    <div className="bg-background-1 relative flex min-h-full w-full flex-col p-6">
      <TopNavPortal>
        <Heading size={HeadingSize.H2} actualSize={HeadingSize.H3} textColor="text-color-2">
          {t('nav.heading')}
        </Heading>
      </TopNavPortal>

      <div className="flex h-[308px] space-x-4">
        <div className="w-72 flex-shrink-0 xl:w-1/4">
          <DashboardBlock>
            <DashboardBlock.Header>
              <div className="flex justify-between gap-4">
                <div>
                  <Tooltip truncatedTextMode text={greeting}>
                    {(tooltip) => (
                      <div className="truncate" {...tooltip}>
                        {greeting}
                      </div>
                    )}
                  </Tooltip>
                </div>
                <div>
                  <SelectListMenu
                    options={userStatsFilterOptions}
                    onBlur={() => setUserStatsFilterOpen(false)}
                    isOpen={userStatsFilterOpen}
                    customListItemRenderer={UserStatListItemRenderer(userStatsFilter)}
                    onClick={(item) => {
                      toggleUserStatItem(item);
                    }}
                    width="w-fit"
                  >
                    {(triggerProps) => (
                      <span {...triggerProps}>
                        <FunnelIcon
                          onClick={() => setUserStatsFilterOpen(true)}
                          className="h-5 w-5"
                          dot={UserstatsFilterAllCount !== userStatsFilter.length}
                        />
                      </span>
                    )}
                  </SelectListMenu>
                </div>
              </div>
            </DashboardBlock.Header>
            <DashboardBlock.Body>
              <SkeletonLoader ready={!isStatsLoading} type="stats">
                <ActivitiesChart
                  due={userStats?.dueToday || 0}
                  overdue={userStats?.overdue || 0}
                  total={userStats?.total || 0}
                  filter={userStatsFilter}
                />
              </SkeletonLoader>
            </DashboardBlock.Body>
          </DashboardBlock>
        </div>
        <div className="w-auto flex-grow">
          <DashboardBlock scrollOverflow>
            <DashboardBlock.Header>
              <div className="flex justify-between">
                <Tooltip truncatedTextMode text={t('blocks.middle.header')}>
                  {(tooltip) => (
                    <div className="truncate" {...tooltip}>
                      {t('blocks.middle.header')}
                    </div>
                  )}
                </Tooltip>
                <Link to={`/clients/${currentClient?.id}/notifications`} className="underline">
                  {t('blocks.middle.link')}
                </Link>
              </div>
            </DashboardBlock.Header>
            <DashboardBlock.Body>
              <SkeletonLoader ready={!unreadNotifications.loading && !notificationsLoader && !!currentClient} type="blockRow" rows={5}>
                <HomePageNotifications unread={unreadNotifications} />
              </SkeletonLoader>
            </DashboardBlock.Body>
          </DashboardBlock>
        </div>
        <div className="w-72 flex-shrink-0 xl:w-1/4">
          <DashboardBlock scrollOverflow>
            <DashboardBlock.Header>
              <div className="flex items-center justify-between">
                <Tooltip truncatedTextMode text={t('blocks.right.header')}>
                  {(tooltip) => (
                    <div className="truncate" {...tooltip}>
                      {t('blocks.right.header')}
                    </div>
                  )}
                </Tooltip>
                {hasPermission(Roles.Management) && (
                  <div className="flex items-center gap-2">
                    <PlusIcon data-cy="add-quick-link-button" className="h-4 w-4" onClick={() => quickLinksRef.current?.addNewQuickLink()} />
                    <CogIcon data-cy="manage-quick-link-button" className="h-5 w-5" onClick={() => quickLinksRef.current?.openManageModal()} />
                  </div>
                )}
              </div>
            </DashboardBlock.Header>
            <DashboardBlock.Body>
              <SkeletonLoader ready={!isQuickLinksLoading} type="blockRow" rows={5}>
                <QuickLinkWidget ref={quickLinksRef} quickLinks={quickLinks} refetchQuickLinks={fetchQuickLinks} />
              </SkeletonLoader>
            </DashboardBlock.Body>
          </DashboardBlock>
        </div>
      </div>

      <div className="-mx-6 mt-12 flex-grow">
        <ActivitiesContext.Provider value={{ type: 'tasks', forCurrentUser: isMyWorkSelected }} key={'hello'}>
          <Activities>
            <Activities.Slot name="AboveFilters">
              <div className="flex w-full justify-center bg-white">
                <TabButton data-cy="tab-my-work" onClick={onMyWorkClick} selected={isMyWorkSelected}>
                  {t('tabs.my-work')}
                </TabButton>
                {hasPermission(Roles.Management) && (
                  <TabButton data-cy="tab-my-team" onClick={onMyTeamClick} selected={!isMyWorkSelected}>
                    {t('tabs.my-team')}
                  </TabButton>
                )}
              </div>
            </Activities.Slot>
          </Activities>
        </ActivitiesContext.Provider>
      </div>
    </div>
  );
};

export default HomePage;

const UserStatListItemRenderer = (
  filter: (Omit<(typeof UserStatsFilterAllDefault)[number], 'text'> & { text: string })[],
): FC<Omit<(typeof UserStatsFilterAllDefault)[number], 'text'> & { text: string }> =>
  function ItemRenderer(props) {
    const ref = useRef<HTMLDivElement>(null);

    const isSelected = !!filter.find((x) => x.id === props.id);
    const anyDeselected = filter.length !== UserstatsFilterAllCount;
    const isDisabled = anyDeselected && isSelected;

    return (
      <div
        ref={ref}
        className={`flex items-center gap-3 ${isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} -my-1`}
        {...mouseAndKeyboardCallbackProps((e) => {
          if (isDisabled) {
            e.stopPropagation();
            e.preventDefault();
          }
        })}
      >
        <Checkbox value={isSelected} onChange={() => ref.current?.click()} disabled={isDisabled} /> {props.text}
      </div>
    );
  };
